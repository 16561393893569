import React, { useEffect, useMemo, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import DefaultLayout from '../layouts/defaultLayout/defaultLayout'
import Breadcrumbs from '../components/breadcrumbs/breadcrumbs'
import Section from '../components/section/section'
import SectionHeadline from '../components/section/sectionHeadline'
import ToolsListing from '../components/toolsListing/toolsListing'
import FilterBar from '../components/filterBar/filterBar'
import filterMethods from '../utils/methodsFilter'
import ToolTile from '../components/toolTile/toolTile'
import ToolTileGrid from '../components/toolTile/toolTileGrid'
import mapMethodQueryResults from '../utils/mapMethodQueryResults'
import { shuffleArray } from '../utils/shuffle'

const mockBreadcrumbs = [
  { title: 'UX Strategy Kit', href: '/' },
  { title: 'Methods', href: '/' },
]

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MethodsPage: React.FC<PageProps<
  Queries.MethodsPageQuery,
  Queries.MethodsPageQueryVariables
>> = ({ data }) => {
  const [filters, setFilters] = useState({})
  const allMethods = useMemo<any[]>(() => mapMethodQueryResults(data), [data])
  const [methods, setMethods] = useState(allMethods)
  const [highlightedMethods, setHighlightesMethods] = useState<any[]>()

  useEffect(() => {
    setMethods(filterMethods(allMethods, filters))
  }, [filters, setMethods, data])
  useEffect(() => {
    const shuffledMethods = shuffleArray(allMethods)
    setHighlightesMethods(shuffledMethods.slice(0, 5))
  }, [data])

  return (
    <DefaultLayout
      className="page-category"
      hasBanner={false}
      seo={{ title: 'Methods' }}
    >
      <Section className="breadcrumbs-section">
        <Breadcrumbs breadcrumbs={mockBreadcrumbs} />
      </Section>
      {highlightedMethods && (
        <Section className="featured-tools-section">
          <header className="section-header section-header--flex">
            <SectionHeadline className="section-header__headline">
              Get to know the methods
            </SectionHeadline>
            <p className="section-header__subline">
              Each method fulfils a different purpose and can be used in various
              situations. Try to <br className="hide-to-xl" />
              filter for the number of workshop participants or the duration of
              your session!
            </p>
          </header>
          <ToolTileGrid>
            {highlightedMethods.map((method: any) => (
              <ToolTile
                category={method.categories[0]}
                duration={method.duration}
                iconUrl={method.thumb}
                key={method.slug}
                link={`/methods/${method.slug}/`}
                participants={method.participants}
                summary={method.headline}
                tags={method.tags}
                title={method.name}
              />
            ))}
          </ToolTileGrid>
        </Section>
      )}
      <Section className="tools-section">
        <header className="section-header section-header--flex section-header--listing">
          <SectionHeadline className="section-header__headline">
            All methods
          </SectionHeadline>
        </header>
        <ToolsListing
          filters={
            <FilterBar
              found={methods.length}
              workflowTags={[]}
              onUpdate={setFilters}
              canSort={false}
            />
          }
          methods={methods}
        />
      </Section>
    </DefaultLayout>
  )
}

export default MethodsPage

export const query = graphql`
  query MethodsPage {
    directus {
      method(limit: -1) {
        name
        logo {
          id
          imageFile {
            publicURL
          }
        }
        headline
        categories {
          category_id {
            slug
          }
        }
        slug
        tags
        participants_from
        participants_to
        duration_from
        duration_to
        gallery {
          directus_files_id {
            id
            imageFile {
              publicURL
            }
          }
        }
        difficulty
      }
    }
  }
`
